.div1{
    margin-top: 10%;
    color: red;
    text-align: center;
}

form{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin:auto;
    gap: 3px;
}

input{
    background-color: black;
    padding: 10px;
}

form button{
    padding: 10px;
    width: 50%;
    margin: 5px auto;
}