* {
  color: white;
  margin: 0;
  padding: 0;
  transition: all 400ms ease;
}

body {
  background-color: #282c34;
  overflow-x: hidden;
}

.content {
  padding-top: 5rem;
}

.heading {
  color: rgb(248, 82, 82);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 2rem;
  text-decoration: underline;
  text-underline-offset: 7px;
}

.subheading-1 {
  color: rgb(248, 82, 82);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.5rem;
  text-decoration: underline;
  text-underline-offset: 7px;
  margin-left: 2%;
}

.primary-button {
  background-color: red;
  border-radius: 2rem;
  font-size: 1rem;
  margin-top: 5rem;
  padding: 1rem;
  text-decoration: none;
  width: 10rem;
}

.secondary-button {
  background-color: #d33348;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 2rem;
  border-color: transparent;
  width: 100%;
  transition: all 300ms ease-in-out;
}

.primary-button:hover {
  scale: 115%;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: rgb(55, 223, 29);
  cursor: pointer;
  transform: scale(1.1);
}

.navbar {
  background-color: #d33349;
  display: grid;
  float: top;
  grid-template-columns: repeat(4, 1fr) 4fr;
  letter-spacing: 0.1rem;
  position: fixed;
  padding: 1%;
  top: 0;
  transition: all 500ms ease;
  width: 100vw;
  z-index: 999;
}

.navbar-link {
  font-size: 1rem;
  text-decoration: none;
}

.navbar.active {
  background: transparent;
  position: absolute;
}

.nav-1 {
  margin-left: 1rem;
}

.nav-2 {
  align-items: center;
  align-self: right;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5%;
}

.nav-2 button{
  width: fit-content;
}

.poster {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4)), url(https://helios-i.mashable.com/imagery/articles/02wTikGJ5ibx559DJk5xNWU/hero-image.fill.size_1248x702.v1623389209.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  font-size: 2rem;
  height: 80vh;
  padding: 10% 10%;
}

.poster-info {
  background: transparent;
}

.explore-sec {
  display:flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2% auto;
}

.books-sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2.5rem;
  row-gap: 5rem;
  justify-content:space-around;
  /* margin: auto; */
}

.book-card {
  display: flex;
  flex-direction: column;
  width: 15%;
  font-size: 0.7rem;
  text-align: left;
  gap: 0.5rem;
  justify-content: space-between;
}

.book-card img {
  height: 37vmin;
}

.book-card .book-info {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  line-height: 1.5rem;
}

.poster-info span {
  color: rgb(255, 104, 104);
}

.counter{
  text-align: center;
  padding: 2rem 0;
  line-height: 2rem;
  font-size: larger;
}


/* media query */
@media only screen and (max-width: 600px) {
  .poster {
    font-size: 1rem;
  }

  .content {
    padding-top: 6rem;
  }

  .nav-1 h1 {
    font-size: 1rem;
  }

  .nav-2 a {
    font-size: 0.8rem;
  }

  .extra-div {
    display: none;
  }

  .navbar {
    padding: 1rem 1rem;
  }

  .navbar button {
    display: none;
  }

  .nav-1 {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-left: 0;
  }

  .nav-2 {
    gap: 0.5rem;
  }

  .nav-2 button,
  .poster-info button {
    padding: 0.5rem;
    border-radius: 1rem;
    font-size: 0.5rem;
  }

  .book-card {
    width: 100%;
  }

  .book-card h2 {
    font-size: 1rem;
  }

  .book-card p {
    font-size: 0.8rem;
  }

  .book-card img {
    height: 25vh;
  }

  .books-sec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    column-gap: 0.7rem;
    row-gap: 3rem;
  }

  .poster{
    padding-top: 50%;
  }

  .poster-info button{
    font-size: 0.8rem;
  }

}